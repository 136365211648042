export const en = {
  translation: {
    header: {
      nav: {
        about: "About us",
        our_cases: "Our cases",
        services: "Services",
        contact: "Contact",
      },
      tooltip: {
        mobile_development: "Desarrollo Móvil",
        web_development: "Desarrollo Web",
        outsourcing: "Outsourcing Tecnológico",
        ui_ux: "Diseño UI/UX",
      },
    },
    home: {
      banner: {
        title: "You have the ideas, we got the team",
        subtitle: "Outsourcing IT",
        text: "Helping our clients in their digital transformation with team extensions",
        btnAbout: "About us",
        btnAnimated: "I want a",
        contact_us: "Contact us",
        text2: "We support companies in their digital transformation",
      },
      trust_in_us: "Trust in us",
      ourTeam: {
        subtitle: "Services",
        title: "What we do?",
        text: "We offer a diversity of digital services and solutions",
      },
      more_about: "Know more",
    },
    weDo: {
      web_development: "Custom web development, corporate web sites, ecommerce",
      mobile_development: "Android and iOS application development",
      outsourcing: "We integrate quality professionals to your team",
      uiUx: "UX, UI, CX, IxD Design",
    },

    services: {
      sectionTitle: "Services",
      titles: {
        web_development: "Web Development",
        mobile_development: "Mobile Development",
        ux_development: "UX & UI Design",
      },
      web_development:
        "We specialize in creating elegant, functional websites with a modern design, always according to the most tested market standards; this allows us to create sites that allow our users to navigate intuitively and easily find the information they are looking for. We use the latest technologies and ensure that your website is fast, secure and optimized for search engines. We offer a wide range of web development services, such as informative websites, ecommerce platforms and technological environments of any kind.",
      mobile_development:
        "We develop customized mobility projects for both iOS and Android operating systems. We offer innovative and functional solutions to help our clients achieve their business goals in this technological area. We use the latest technologies to create fast, secure and easy to use applications.",
      ux_development:
        "Unow's team includes designers and UX / UI /CX experts committed to creating and designing engaging digital solutions. Our Web design is focused on usability and always aligned with the corporate identity of our clients. We offer a wide range of services in this area, from the creation of wireframes and prototypes, to the complete design of your website.",
    },

    methodologies: {
      best_practices: {
        title: "Best practices",
        description:
          "We are addicted to clean code and solid principles. Git flow, docker, jenkins. We like to work with the most standard tools that make life easier for all of us.",
      },
      agile_methods: {
        title: "Agile methods",
        description:
          "We do scrum and we are passionate about it. If it doesn't fit the project, we adapt ourselves. We believe in partial releases to see and play with the software. There is no better feedback.",
      },
      keep_in_touch: {
        title: "Keep in touch",
        description:
          "We believe in being part of your team. We believe in daily mini-meetings and constant communication. We want to integrate with you to be able to make the tool you need.",
      },
    },

    customer_reviews: {
      title: "Customer reviews",
      indra: {
        review:
          "As professionals they are very committed to their work and to the results, they are very reliable and the quality of their deliveries are impeccable. They are efficient, flexible and very easy to work with",
        customer_name: "Angel Gorriana Gonzalez",
        customer_position: "Responsible for TMobilitat in Fujitsu ",
      },
      full_street: {
        review:
          "I highly recommend this team. And if you're looking for integrity, they're your guys",
        customer_name: "Chrystèle SANON",
        customer_position: "CEO and Founder FULL’STREET",
      },
      leads_for_finance: {
        review:
          "Authentic professional with a lot of experience and very broad knowledge in WEB development, with unique capabilities to develop very complex systems and realize them with maximum ease and transparency.",
        customer_name: "Sergey Holovanov",
        customer_position: "CTO and Co-Founder",
      },
    },
    outsourcing: {
      titles: {
        outsourcing: "OUTSOURCING IT",
        what_is_it_outsourcing: "What is IT Outsourcing?",
        when_to_use_outsourcing:
          "In which situations outsourcing services are an excellent option for companies:",
        benefits: "Benefits",
        our_benefits: "Our benefits",
        infrastructure_reduction: "Reduced infrastructure costs.",
        transformation_costs:
          "Transformation from fixed to variable costs, as well as flexibility in the creation of teams.",
        competitive_advantage: "Improved quality and competitiveness.",
        focus_activities: "Can focus on their core business",
        our_process: "Our process",
        how_we_do_it: "How we do it?",
        private: "Privacy and ",
        confidentiality: "confidentiality",
        international: "International",
        breaking_boundaries: "Breaking boundaries",
      },
      content: {
        what_is_it_outsourcing:
          "The outsourcing of IT services encompasses the areas of support, programming and development, the latter being the areas in which Unow brings its knowledge and experience to our customers. We ensure, by working successfully, the correct selection and management of profiles of different technical natures, such as, among others:",
        frontend_developers: "FrontEnd Developers",
        backend_developers: "BackEnd Developers",
        fullstack_developers: "FullStack Developers",
        devops: "DevOps",
        project_managers: "Project Managers",
        moments_of_workload:
          "Moments of workload that make it impossible to carry out the projects with own resources.",
        situation_in_which:
          "Situations in which the deadline of the projects is not possible to deliver on time, with the size of the current team.",
        not_having_the_necessary:
          "Not having the necessary recruitment team to carry out complex selection processes.",
        external_assistance:
          "External assistance in the search and recruitment of profiles with specific technologies.",
        our_benefits:
          "The extra benefit of outsourcing your projects to the Unow team: Significant savings in your IT development costs and a more economically thanks to our dominance and presence in the LATAM market, which allows us to contain tariffs compared to the European market.",
        infrastructure_reduction:
          "Our services are remarkably competitive and have interesting costs to boost your company.",
        transformation_costs:
          "Forget about having fixed expenses, you can start and stop them whenever you want without affecting your finances.",
        competitive_advantage:
          "Contracting specific expertise for specific projects, often of indeterminate duration and scope. On-demand access to resources with the latest and most current technologies.",
        focus_activities:
          "Relegating specialized IT providers to those 'non-core' projects that are essential to your digital evolution.",
        how_we_do_it: [
          "Thanks to our recruitment department specialized in IT processes and its extensive knowledge of the digital labor market.",
          "Unow's mission is to achieve the perfect match between the needs of our clients and the selected profiles, after applying our extensively tested process.",
        ],
        privacity_and_confidentiality:
          "Unow cares about the privacy and confidentiality of our clients' projects, for us the peace of mind of our clients is paramount, that is why we take care of the information that passes through our hands.",
        breaking_boundaries:
          "Our clients are from diverse industries, speak different languages, and are located all over the world. The United States, Latin America and Europe are some of our clients' locations.",
        clients_worldwide: "Clients worldwide who have worked with us",
        countries_customer: "Countries where our customers belong",
        customers_are_satified:
          "Of our customers are satisfied with our remote work methodology.",
      },
    },
  },
};
