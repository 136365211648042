export const es = {
  translation: {
    header: {
      nav: {
        about: "Nosotros",
        our_cases: "Casos",
        services: "Servicios",
        contact: "Contacto",
      },
      tooltip: {
        mobile_development: "Desarrollo Móvil",
        web_development: "Desarrollo Web",
        outsourcing: "Outsourcing Tecnológico",
        ui_ux: "Diseño UI/UX",
      },
    },
    home: {
      banner: {
        title: "Tienes las ideas, nosotros el equipo",
        subtitle: "Outsourcing IT",
        text: "Ayudando a nuestros clientes en su transformación digital",
        btnAbout: "Conócenos",
        btnAnimated: "Busco un",
        contact_us: "Contáctanos",
        text2: "Acompañamos a las empresas en su transformación digital",
      },
      trust_in_us: "Confian en nosotros",
      ourTeam: {
        subtitle: "Servicios",
        title: "¿Que hacemos?",
        text: "Ofrecemos una diversidad de servicios y soluciones digitales",
      },
      more_about: "Conoce más",
    },

    weDo: {
      web_development: "Desarrollo web a medida, webs corporativas, ecommerce",
      mobile_development: "Desarrollo de aplicaciones Android y iOS",
      outsourcing: "Integramos profesionales de calidad a tu equipo",
      uiUx: "Diseño de UX, UI, CX, IxD",
    },

    services: {
      section_title: "Servicios",
      titles: {
        web_development: "Desarrollo Web",
        mobile_development: "Desarrollo Móvil",
        ux_development: "Diseño UI / UX",
      },
      web_development:
        "Somos especialistas en generar sitios web elegantes, funcionales y con un diseño moderno, siempre según los más testados estándares del mercado; esto nos permite crear sites que permiten a nuestros usuarios, navegar de manera intuitiva y encontrar fácilmente la información que buscan. Utilizamos las últimas tecnologías y garantizamos que su web sea rápida, segura y optimizada para los motores de búsqueda. Ofrecemos una amplia gama de servicios de desarrollo web, tales como webs informativas, plataformas ecommerce y entornos tecnológicos de cualquier índole.",
      mobile_development:
        "Desarrollamos proyectos de movilidad personalizadas, tanto para sistemas operativos iOS como en Android. Ofrecemos soluciones innovadoras y funcionales para ayudar a nuestros clientes a alcanzar sus objetivos empresariales en este ámbito tecnológico. Utilizamos las últimas tecnologías para crear aplicaciones rápidas, seguras y fácil de usar.",
      ux_development:
        "Unow cuenta en su equipo con diseñadores y expertos en UX / UI /CX comprometidos en crear y diseñar soluciones digitales atractivas. Nuestro diseño Web apuesta sobre todo por la usabilidad y siempre alineado con la identidad corporativa de nuestros clientes. Ofrecemos una amplia gama de servicios en este ámbito, desde la creación de wireframes y prototipos, hasta el diseño completo de su página web.",
    },

    methodologies: {
      best_practices: {
        title: "Buenas prácticas",
        description:
          "Somos adictos a clean code y principios solid. Git flow, docker, jenkins. Nos gusta trabajar con las herramientas más estándar y que nos faciliten la vida a todos",
      },
      agile_methods: {
        title: "Métodos ágiles",
        description:
          "Hacemos scrum y nos apasiona. Si no se adapta al proyecto, nos adaptamos nosotros. Creemos en ent regables parciales para ver y jugar con el software. No hay mejor retro-alimentación",
      },
      keep_in_touch: {
        title: "Mantente en contacto",
        description:
          "Creemos en ser parte de tu equipo. Creemos en las mini-reuniones diarias, y en la comunicación constante. Queremos integrarnos contigo para poder hacer la herramienta que necesitas",
      },
    },

    customer_reviews: {
      title: "Reseñas de nuestros clientes",
      indra: {
        review:
          "Como profesional es una persona muy comprometida con su trabajo y con los resultados, cumplidor y con una calidad en las entregas, impecable. Es eficiente, flexible y ha resultado muy fácil trabajar con él",
        customer_name: "Angel Gorriana Gonzalez",
        customer_position: "Responsable de TMobilitat en Fujitsu",
      },
      full_street: {
        review:
          "Recomiendo profundamente a este equipo. Y si buscas integridad, ellos son tus hombres",
        customer_name: "Chrystèle SANON",
        customer_position: "CEO y Founder FULL’STREET",
      },
      leads_for_finance: {
        review:
          "Profesional autentico con mucha experiencia y conocimientos muy amplios en desarrollo WEB, con capacidades únicos de desarrollar sistemas muy complejos y realizarlos con máxima facilidad y transparencia",
        customer_name: "Sergey Holovanov",
        customer_position: "CTO y Co-Founder",
      },
    },
    outsourcing: {
      titles: {
        outsourcing: "OUTSOURCING IT",
        what_is_it_outsourcing: "¿Qué es el Outsourcing?",
        when_to_use_outsourcing:
          "En que situaciones los servicios de Outsourcing IT son una excelente opción para las empresas:",
        benefits: "Beneficios",
        our_benefits: "Nuestros beneficios",
        infrastructure_reduction: "Reducción de costes de infraestructura.",
        transformation_costs:
          "Trasformación de costes fijos a variables, así como la flexibilidad en la creación de los equipos.",
        competitive_advantage: "Mejoras en calidad y competitividad.",
        focus_activities: "Pueden centrar en su actividad principal.",
        our_process: "Nuestro proceso",
        how_we_do_it: "¿Cómo lo hacemos?",
        privacity: "Privacidad y ",
        confidentiality: "confidencialidad",
        international: "Internacional",
        breaking_boundaries: "Rompemos las fronteras",
      },
      content: {
        what_is_it_outsourcing:
          "La externalización de servicios IT engloba tanto las áreas de soporte, como las de programación y desarrollo, siendo estas últimas en las que Unow aporta su conocimientos y experiencia a nuestros clientes. Aseguramos trabajando a éxito, la correcta selección y gestión de perfiles de diferente índole técnica, como pueden ser entre otros:",
        frontend_developers: "FrontEnd Developers",
        backend_developers: "BackEnd Developers",
        fullstack_developers: "FullStack Developers",
        devops: "DevOps",
        project_managers: "Project Managers",
        moments_of_workload:
          "Momentos de carga de trabajo que hagan imposible llevarse a cabo los proyectos con los recursos propios.",
        situation_in_which:
          "Situaciones en que el plazo de los proyectos no sea posible su correcta entrega en tiempos, con la dimensionalidad del equipo actual.",
        not_having_the_necessary:
          "No tener el equipo de contratación de personal para llevar a cabo procesos de selección complejos.",
        external_assistance:
          "Desear una ayuda externa en la búsqueda y contratación de perfiles con tecnologías específicas.",
        our_benefits:
          "Beneficio extra que le aportará externalizar sus proyectos al equipo de Unow: Un considerable ahorro en sus costes de desarrollo informático y una trasformación digital más asumible económicamente; gracias a nuestro dominio y presencia del mercado LATAM, que nos permite una contención de tarifas en comparación con el mercado europeo.",
        infrastructure_reduction:
          "Nuestros servicios son destacablemente competitivos teniendo costos interesantes para el impulso de tu empresa.",
        transformation_costs:
          "Olvídate de tener gastos fijos, puedes comenzarlos y pausarlos en el momento que quieras sin tener una afectación en tus finanzas.",
        competitive_advantage:
          "Al contratar el Know-how concreto para proyectos específicos, en muchas ocasiones de duración y alcance indeterminados. Acceso ondemand a los recursos con las tecnologías mas punteras y actuales.",
        focus_activities:
          "Relegando en los proveedores de ITespecializados, aquellos proyectos “no Core”, peroindispensables para su evolución digital.",
        how_we_do_it: [
          "Gracias a nuestro departamento de recruitment especializado en procesos IT y su amplio conocimiento del mercado laboral digital.",
          "La misión de Unow es conseguir el match perfecto, ente las necesidades que nos trasladan nuestros clientes y los perfiles seleccionados, después de aplicar nuestro proceso ampliamente testado.",
        ],
        privacity_and_confidentiality:
          "Unow se preocupa por la privacidad y confidencialidad de los proyectos de nuestros clientes, para nosotros la tranquilidad de nuestros clientes es primordial por ello nos precupamos por cuidar la información que pasa por nuestras manos.",
        breaking_boundaries:
          "Nuestros clientes son de diversas industrias, hablan idiomas distintos, y están localizados por todo el mundo. Estados Unidos, Latinoamérica y Europa, son algunas de las ubicaciones de nuestros clientes.",
        clients_worldwide:
          "Clientes al rededor del mundo que han trabajado con nosotros",
        countries_customer: "Paises a los que pertenecen nuestros clientes",
        customers_are_satified:
          "De nuestros clientes estan satifechos con nuestra metodología de trabajo remoto",
      },
    },
  },
};
