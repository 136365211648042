import "assets/scss/index.scss";
import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "reportWebVitals";

import "./i18n";

const App = lazy(() => import("App"));

const Spinner = () => {
  return (
    <div className="preloader__fullScreen">
      <div className="preloader">
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};

export default Spinner;

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<Spinner />}>
      <App />
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
